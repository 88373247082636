import { IUseAuthenticationLogic } from "@src/components/feedback/AuthenticationContent/UseAuthenticationLogic";
import { Controller } from "react-hook-form";
import FlexBox from "design-system/src/Components/FlexBox/FlexBox";
import React, { FC } from "react";
import { useTranslation } from "@src/hooks/useTranslation";
import CheckBox from "design-system/src/Form/CheckBox/CheckBox";
import SubmitButton from "@src/components/feedback/AuthenticationContent/Form/SubmitButton";
import Typography from "design-system/src/Foundation/Typography/Typography";
import { EAuthentificationStep } from "@src/components/feedback/AuthenticationContent/AuthenticationContent";
import { usePhoneUtils } from "@src/backbone/prototypes/phoneUtils";
import CustomerFields from "@src/components/feedback/AuthenticationContent/Form/CustomerFields";
import useResponsive from "@src/hooks/useResponsive";

interface ICustomerFieldsStepProps {
  authentificationLogic: IUseAuthenticationLogic;
}
const CustomerFieldsStep = (props: ICustomerFieldsStepProps) => {
  const { t } = useTranslation(["Account", "UI"]);
  usePhoneUtils();
  const { authentificationLogic } = props;
  const { control, customerFields, optInFields, onSubmit, isLoading } =
    authentificationLogic;
  const isSignUp = authentificationLogic.step === EAuthentificationStep.SIGN_UP;
  const { isMobile } = useResponsive();

  return (
    <FlexBox direction="column" gap={12} flex={1}>
      {<CustomerFields customerFields={customerFields} control={control} />}
      {/* OptIns CheckBoxes */}
      <FlexBox direction={"column"} gap={24} style={{ padding: "12px 0 24px" }}>
        {optInFields.map(({ id, label, required = false }) => (
          <Controller
            name={id}
            control={control}
            rules={{ required }}
            render={({ field, fieldState }) => {
              return (
                <CheckBox
                  align={"top"}
                  paddingLeft={36}
                  checked={field.value}
                  label={
                    <Typography
                      variant={"ui4"}
                      color={!!fieldState.error ? "error" : "greyText"}
                      dangerouslySetInnerHTML={{
                        __html: label,
                      }}
                    />
                  }
                  {...field}
                />
              );
            }}
          />
        ))}
      </FlexBox>
      <SubmitButton
        loading={isLoading}
        onClick={onSubmit}
        style={{ ...(isMobile && { margin: "0 0 16px" }) }}
      >
        {isSignUp ? t("Créer mon compte", "Account") : t("Valider", "UI")}
      </SubmitButton>
    </FlexBox>
  );
};
export default CustomerFieldsStep;
