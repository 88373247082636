import React, { ReactNode, useState } from "react";
import useBreakpoint from "../../../Components/Grid/hooks/useBreakpoint";
import Typography from "../../../Foundation/Typography/Typography";
import {
  _ProductDetailsInfoRoot,
  _ProductDetailsInfo_MainList,
  _ProductDetailsInfo_MainList_Separator,
  _ProductBoostBannerRoot,
} from "./ProductDetailsInfo.styled";
import htmlParse from "html-react-parser";
import Button from "../../../Foundation/Button/Button";
import { ITheme, ThemeMode } from "../../../Themes/defaultTheme";
import Image from "../../../Components/Image/Image";
import { CSSProperties } from "styled-components";

export interface IProductBoost {
  title: string;
  cta: string;
  message: string;
  theme: ITheme;
  bg_color: string;
  bg_align: string;
  id_picture: string;
  bg_picture: string;
  onClick?: () => void;
}
export interface IProductDetailsInfoProps {
  category: ReactNode;
  name: ReactNode;
  subtitle?: Array<string | ReactNode>;
  weightPrice?: string;
  description?: string;
  product_boost?: null | IProductBoost;
  showMoreLabel: string;
  showLessLabel: string;
}

const ProductBoostBanner = (product_boost: IProductBoost) => {
  const {
    title,
    cta,
    message,
    theme,
    bg_color = "none",
    id_picture,
    bg_picture,
    bg_align = "center",
    onClick,
  } = product_boost;
  const mode = ThemeMode(theme);

  return (
    <_ProductBoostBannerRoot
      direction={"column"}
      style={{ background: bg_color }}
      $bgPosition={bg_align as CSSProperties["backgroundPosition"]}
      onClick={onClick}
    >
      {bg_picture && parseInt(bg_picture) > 0 && (
        <Image image={{ id: bg_picture }} />
      )}
      <Typography variant="h6" theme={mode}>
        {title}
      </Typography>
      <Typography as={"p"} variant="body" theme={mode}>
        {message}
      </Typography>
      <Button {...{ onClick, theme }}>{cta}</Button>
    </_ProductBoostBannerRoot>
  );
};
const ProductDetailsInfo = (props: IProductDetailsInfoProps) => {
  const {
    category,
    name,
    subtitle,
    weightPrice,
    description,
    product_boost,
    showMoreLabel = "Voir plus",
    showLessLabel = "Voir moins",
  } = props;
  const { isMobile } = useBreakpoint();
  const [showFullDescription, setShowFullDescription] = useState(false);

  // Function to extract the first sentence from HTML string
  const getFirstSentence = (htmlString: string) => {
    // Use regex to extract text content from HTML
    const textContent = htmlString.replace(/<[^>]*>/g, "");

    // Find the first period followed by a space or end of string
    const periodIndex = textContent.search(/\.\s|\.$/) + 1;

    if (periodIndex > 0) {
      // We need to get the HTML for the first sentence
      // First, get the plaintext content up to the first period
      const firstSentenceText = textContent.substring(0, periodIndex);

      // Then extract HTML that contains this text
      let charCount = 0;
      let tagStack = 0;
      let htmlFirstSentence = "";

      for (let i = 0; i < htmlString.length; i++) {
        const char = htmlString[i];
        htmlFirstSentence += char;

        // Track opening and closing tags
        if (char === "<") {
          tagStack++;
        } else if (char === ">") {
          tagStack--;
        } else if (tagStack === 0) {
          // Only count characters that are not within tags
          charCount++;

          // If we've counted enough characters to match our first sentence
          if (charCount >= firstSentenceText.length) {
            // Close any open tags
            const openTags =
              htmlFirstSentence.match(/<([a-z][a-z0-9]*)[^>]*>/gi) || [];
            const closedTags =
              htmlFirstSentence.match(/<\/([a-z][a-z0-9]*)>/gi) || [];

            // Count tags by name
            const tagCount: Record<string, number> = {};
            openTags.forEach((tag) => {
              const name = tag.match(/<([a-z][a-z0-9]*)/i)?.[1]?.toLowerCase();
              if (name) {
                tagCount[name] = (tagCount[name] || 0) + 1;
              }
            });

            closedTags.forEach((tag) => {
              const name = tag.match(/<\/([a-z][a-z0-9]*)/i)?.[1]?.toLowerCase();
              if (name) {
                tagCount[name] = (tagCount[name] || 0) - 1;
              }
            });

            // Add closing tags for any unclosed tags (in reverse order)
            Object.keys(tagCount).forEach((tag) => {
              const count = tagCount[tag];
              for (let j = 0; j < count; j++) {
                htmlFirstSentence += `</${tag}>`;
              }
            });

            break;
          }
        }
      }

      return htmlFirstSentence;
    }

    return htmlString; // Return the whole string if no period found
  };

  const renderDescription = () => {
    if (!description) return null;

    const hasMultipleSentences = description.includes(".");

    if (!hasMultipleSentences || showFullDescription) {
      return (
        <>
          <Typography mt={15} variant="body">
            {htmlParse(description)}
            {hasMultipleSentences && (
              <Typography
                as="span"
                variant="body"
                color="champagne3"
                onClick={() => setShowFullDescription(false)}
                style={{ padding: "0", paddingLeft: "4px", cursor: "pointer" }}
              >
                {showLessLabel}
              </Typography>
            )}
          </Typography>
        </>
      );
    }

    const firstSentence = getFirstSentence(description);

    return (
      <>
        <Typography mt={15} variant="body">
          {htmlParse(firstSentence)}
          <Typography
            as="span"
            variant="body"
            color="champagne3"
            onClick={() => setShowFullDescription(true)}
            style={{ padding: "0", paddingLeft: "4px", cursor: "pointer" }}
          >
            {showMoreLabel}
          </Typography>
        </Typography>
      </>
    );
  };

  return (
    <_ProductDetailsInfoRoot>
      <Typography
        variant="ui3"
        color="black"
        style={{ textTransform: "uppercase" }}
      >
        {category}
      </Typography>
      <Typography
        mt={16}
        variant={isMobile ? "h2" : "h4"}
        color="black"
        as={"h1"}
        style={{ textTransform: "uppercase" }}
      >
        {name}
      </Typography>
      {subtitle && (
        <_ProductDetailsInfo_MainList>
          {subtitle?.map((info, index) => {
            return (
              <React.Fragment key={index}>
                {index > 0 && <_ProductDetailsInfo_MainList_Separator />}
                <Typography variant="subtitle2">{info}</Typography>
              </React.Fragment>
            );
          })}
        </_ProductDetailsInfo_MainList>
      )}
      {weightPrice && (
        <Typography variant="ui3" color={"greyText2"}>
          {weightPrice}
        </Typography>
      )}
      {renderDescription()}
      {product_boost && <ProductBoostBanner {...product_boost} />}
    </_ProductDetailsInfoRoot>
  );
};

export default ProductDetailsInfo;
