import SignInButton from "design-system/src/Components/SignInButton/SignInButton";
import SignInWithApple from "@src/components/feedback/SignInWithApple/SignInWithApple";
import FlexBox from "design-system/src/Components/FlexBox/FlexBox";
import React, { createRef, useEffect, useRef, useState } from "react";
import { IUseAuthenticationLogic } from "@src/components/feedback/AuthenticationContent/UseAuthenticationLogic";
import { useTranslation } from "@src/hooks/useTranslation";
import useFacebookConnectSdk from "@src/lib/FacebookConnect/useFacebookConnectSdk";
import Divider from "design-system/src/Components/Divider/Divider";
import Input, {
  EInputType,
  EInputVariant,
} from "design-system/src/Form/Input/Input";
import { useLazyPostCheckEmailQuery } from "@src/services/rollingStartApi";
import { $champagne } from "design-system/src/Utils/color";
import styled, { css } from "styled-components";
import Button from "design-system/src/Foundation/Button/Button";
import Typography from "design-system/src/Foundation/Typography/Typography";
import TypographyLink from "@src/lib/rolling-start-navigation/TypographyLink";
import { useConfiguration } from "@src/hooks/query/useConfiguration";
interface ISocialConnectStepProps {
  authentificationLogic: IUseAuthenticationLogic;
}

interface IEmailFormStepProps {
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (isSignIn: boolean) => void;
}

const EmailFormStepRoot = styled(FlexBox)(
  () => css`
    .input-email-form-step {
      padding-left: 24px;
      padding-right: 24px;

      & .input {
        padding-left: 64px;
        margin-left: -64px;
      }
    }
  `
);

const EmailFormStep = (props: IEmailFormStepProps) => {
  const { value, onChange, onSubmit } = props;
  const { t } = useTranslation(["Account"]);
  const [email, setEmail] = useState(value);
  const [checkEmail, { isLoading: isCheckingEmail }] =
    useLazyPostCheckEmailQuery();

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const res = await checkEmail({ email: email! }).unwrap();
    onSubmit(res.exist);
  };

  return (
    <EmailFormStepRoot direction="column" gap={12} as="form">
      <Input
        rootClassName="input-email-form-step"
        label={t("Email", "Account")}
        name="email"
        type={EInputType.EMAIL}
        variant={EInputVariant.FILL}
        icon="message"
        onChange={(e) => {
          setEmail(e.target.value);
          onChange(e);
        }}
        value={email}
        loading={isCheckingEmail}
        autoComplete="email"
        inputMode="email"
      />
      <input
        type="password"
        name="password"
        style={{
          marginTop: "-12px",
          backgroundColor: "transparent",
          border: "none",
          outline: "none",
          height: 0,
          position: "relative",
          zIndex: -1,
        }}
      />
      <Button
        onClick={handleSubmit}
        style={{ width: "100%" }}
        variant="champagne"
        size="extra-large"
        type="submit"
        loading={isCheckingEmail}
        disabled={!email || !/\S+@\S+\.\S+/.test(email)}
      >
        {t("Continuer", "Account")}
      </Button>
    </EmailFormStepRoot>
  );
};
const SocialConnectStep = (props: ISocialConnectStepProps) => {
  const { t } = useTranslation(["Account", "Authentification"]);
  const googleButtonRef = createRef<HTMLButtonElement>();
  const { authentificationLogic } = props;
  const {
    onClickFacebook,
    onClickGoogle,
    onClickSignInEmail,
    onClickSignUpEmail,
    attachGoogleSignIn,
    isSigningFacebook,
    isSigningGoogle,
    isSigningApple,
    setValue,
    getValues,
    onClickApple,
  } = authentificationLogic;

  const facebookConnect = useFacebookConnectSdk({});
  const { getConfiguration: c } = useConfiguration();
  const accountActivationLink = c("_MY_SUSHISHOP_ACCOUNT_ACTIVATION_LINK_", "");

  useEffect(() => {
    if (googleButtonRef.current) {
      attachGoogleSignIn(googleButtonRef.current);
    }
  }, [attachGoogleSignIn, googleButtonRef]);

  return (
    <FlexBox direction="column" gap={12} flex={1}>
      <SignInButton
        disabled={
          facebookConnect.isProcessing ||
          !facebookConnect.isSdkLoaded ||
          !facebookConnect.isMounted
        }
        icon="logo_facebook_colorized"
        onClick={onClickFacebook}
        loading={isSigningFacebook}
      >
        {t("Continuer avec Facebook", "Account")}
      </SignInButton>
      <SignInWithApple onSuccess={onClickApple} loading={isSigningApple} />
      <SignInButton
        ref={googleButtonRef}
        icon="logo_google_colorized"
        onClick={onClickGoogle}
        loading={isSigningGoogle}
      >
        {t("Continuer avec Google", "Account")}
      </SignInButton>
      <Divider
        mt={14}
        mb={14}
        weight={1}
        direction="horizontal"
        text={t("ou", "Account")}
        color={$champagne}
      />
      <EmailFormStep
        value={getValues("email")}
        onChange={(e) => {
          setValue("email", e.target.value);
        }}
        onSubmit={(isSignIn) => {
          if (isSignIn) {
            onClickSignInEmail();
          } else {
            onClickSignUpEmail();
          }
        }}
      />

      {accountActivationLink && (
        <div
          style={{
            marginTop: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <TypographyLink
            variant="ui4"
            color="champagne3"
            to={accountActivationLink}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            {t("Activer un compte créé en shop", "Authentification")}
          </TypographyLink>
        </div>
      )}
    </FlexBox>
  );
};

export default SocialConnectStep;
